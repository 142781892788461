<template>
  <div class="mod-user">
    <div class="container-left" :style="{ height: clientHeight + 'px' }">
      <el-input placeholder="输入关键字进行过滤" v-model="filterText" />
      <div class="container-left-tree">
        <el-tree
          ref="tree"
          :data="orgTree"
          :props="defaultProps"
          node-key="id"
          highlight-current
          @node-click="treeNodeClick"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpanded"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
    </div>
    <div class="container-right">
      <div class="buttonOut">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)">
          <el-form-item>
            <el-select v-model="dataForm.userType" size="small" placeholder="账号类型">
              <el-option label="管理端账号" :value="0"></el-option>
              <el-option label="客户端账号" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.userName" placeholder="用户名" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="currentChangeHandle(1)" size="small">查询</el-button>
            <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle()" size="small"
              >新增</el-button
            >
            <!-- <el-button
              v-if="isAuth('sys:user:delete')"
              type="danger"
              @click="deleteHandle()"
              :disabled="dataListSelections.length <= 0"
              size="small"
              >批量删除</el-button
            > -->
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 100%; padding: 8px">
        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle">
          <!-- <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          >
          </el-table-column>
          -->
          <!-- <el-table-column
          prop="userId"
          header-align="center"
          align="center"
          width="80"
          label="ID">
        </el-table-column> -->
          <el-table-column prop="username" header-align="center" align="center" label="用户名"> </el-table-column>
          <el-table-column prop="email" header-align="center" align="center" label="邮箱"> </el-table-column>
          <el-table-column prop="mobile" header-align="center" align="center" label="手机号"> </el-table-column>
          <el-table-column prop="status" header-align="center" align="center" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 0" size="small" type="danger">禁用</el-tag>
              <el-tag v-else size="small">正常</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" header-align="center" align="center" width="180" label="创建时间">
          </el-table-column>
          v-if="!dataForm.userType"
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            :width="curUserType == 1 ? 300 : 240"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button v-if="isAuth('sys:user:updateUserNode')" type="text" @click="edit(scope.row)"
                >分配节点</el-button
              >
              <el-button v-if="curUserType == 1" type="text" @click="sendCar(scope.row)">分配车辆</el-button>
              <el-button
                v-if="isAuth('sys:user:lock') || isAuth('sys:user:unlock')"
                type="text"
                size="small"
                :style="{ color: scope.row.status == 0 ? '#67C23A' : '#F56C6C' }"
                @click="lockOrUnlock(scope.row)"
                >{{ scope.row.status == 0 ? "解锁" : "锁定" }}</el-button
              >
              <el-button
                size="small"
                type="text"
                style="color: #e6a23c"
                @click="resetPwd(scope.row)"
                v-if="isAuth('sys:user:resetpassword')"
                >重置密码</el-button
              >
              <el-button
                v-if="isAuth('sys:user:del')"
                type="text"
                size="small"
                style="color: #f56c6c"
                @click="deleteHandle(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update
          :orgData="orgData"
          :userType="curUserType"
          :nodeName="curNode.nodeName"
          v-if="addOrUpdateVisible"
          ref="addOrUpdate"
          @refreshDataList="getDataList"
        ></add-or-update>
        <!-- :menuOptions="orgTree" -->
        <send-car-dialog ref="sendCarDialog" @getList="getDataList" />
      </div>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./user-add-or-update";
import sendCarDialog from "./sendCarDialog";
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      cs: true,
      dataForm: {
        userName: "",
        userType: 0,
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      orgTree: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      curNode: {},
      filterText: "",
      curUserType: 0, //点击查询保存的userType, 修改时有用
    };
  },
  components: {
    AddOrUpdate,
    sendCarDialog,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    clientHeight() {
      return document.documentElement["clientHeight"] - 122;
    },
    defaultExpanded() {
      let _B = Boolean(this.orgTree && this.orgTree.length);
      let arr = _B ? [this.orgTree[0].id] : [];
      return arr;
    },
    orgData() {
      let orgData = this.curNode.nodeType || this.curNode.nodeType == 0 ? JSON.parse(JSON.stringify(this.curNode)) : {};
      return orgData;
    },
  },
  activated() {
    this.getOrgList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getOrgList() {
      //获取组织架构列表
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/list"),
        methods: "get",
      })
        .then((res) => {
          this.orgTree = treeDataTranslate(res.data.data, "id", "parendId");
          this.curNode = this.orgTree && this.orgTree.length ? this.orgTree[0] : {};
          this.$refs.tree.setCurrentKey(this.curNode.id);
          this.currentChangeHandle(1);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      //点击树节点
      this.curNode = JSON.parse(JSON.stringify(node));
      this.currentChangeHandle(1);
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/sys/user/list"),
        method: "get",
        params: this.$http.adornParams({
          page: this.pageIndex,
          limit: this.pageSize,
          username: this.dataForm.userName,
          userType: this.dataForm.userType,
          orgId: this.curNode.id,
        }),
      })
        .then(({ data }) => {
          if (data && data.code === 200) {
            this.dataList = data.page.list;
            this.totalPage = data.page.totalCount;
            this.curUserType = JSON.parse(JSON.stringify(this.dataForm.userType));
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false;
        })
        .catch((err) => {
          this.dataListLoading = false;
          console.log(err);
          this.$message.error(err.msg);
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增
    addOrUpdateHandle() {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init();
      });
    },
    // 删除
    deleteHandle(row) {
      let userId = row.userId;
      this.$confirm(`确定对[用户:${row.username}]进行[删除]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/sys/user/del"),
            method: "delete",
            params: {
              userId,
            },
          }).then(({ data }) => {
            if (data && data.code === 200) {
              this.getDataList();
              this.$message({
                message: "操作成功",
                type: "success",
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch((err) => {
          if (err && err.msg) this.$message.error(err.msg);
        });
    },
    resetPwd(row) {
      this.$confirm(`确定对[用户:${row.username}]进行[重置密码]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/sys/user/resetpassword"),
            method: "put",
            params: {
              userId: row.userId,
            },
          })
            .then((res) => {
              this.$message.success(res.data.msg);
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        })
        .catch(() => {});
    },
    lockOrUnlock(row) {
      if (row && (row.status == 0 || row.status == 1)) {
        //status == 0禁用状态-进行解锁, status == 1正常状态，进行锁定。
        this.$confirm(`确定对[用户:${row.username}]进行[${row.status == 0 ? "解锁" : "锁定"}]操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            return this.$http({
              url: this.$http.adornUrl(`/sys/user/${!row.status ? "unlock" : "lock"}`),
              method: "put",
              params: {
                userId: row.userId,
              },
            });
          })
          .then((res) => {
            this.$message.success(res.data.msg);
            this.getDataList();
          })
          .catch((err) => {
            if (err && err.msg) {
              this.$message.error(err.msg);
            }
          });
      }
    },
    edit(row) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init({ ...row, userType: this.curUserType });
      });
    },
    sendCar(row) {
      if (!this.isAuth("sys:user:selectUserOrgArchId"))
        return this.$message.warning("没有权限!sys:user:selectUserOrgArchId");
      this.$http({
        url: this.$http.adornUrl(`sys/user/selectUserOrgArchId`),
        method: "get",
        params: this.$http.adornParams({
          userType: this.curUserType,
          userId: row.userId,
        }),
      })
        .then((res) => {
          if (!res.data.data || !res.data.data.length) return this.$message.warning("请先修改节点!");
          this.$refs.sendCarDialog.show(row);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>